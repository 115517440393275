import React from "react";
import Layout from "../../components/layout";
import HeaderOne from "../../components/header/header-one";
import StickyHeader from "../../components/header/sticky-header";
import Footer from "../../components/footer";
import i18n from "i18n-js";
import Story from "../../components/about/our-story";
import ComingSoon from "../Work/Coming-soon";
const AshaWorkers = () => {
  return (
    <Layout pageTitle="Our Story">
      <HeaderOne />
      <StickyHeader />
      <Story/>
      <Footer />
    </Layout>
  );
};
export default AshaWorkers